@import '../../global.css';

.grid-layout-noter {
    
    .grid-item-logo {
        grid-area: 2 / 15 / 3 / 18;
    }
    
    .grid-item-category {
        grid-area: 1 / 11 / 2 / 15;
        
        h2 {
            @mixin layoutcalc font-size, 12, 0.15;
            line-height: 1;
            font-weight: 400;
            text-align: center;
        }
        
    }
    
    .grid-item-link{
        background: #9E8D7D;    
    }
    
    .grid-item-text {
        grid-area: 9 / 5 / 12 / 18;   
        
    }

    .color-1 {
        background-color: #CBCBCB80;
    }
    
    .color-2 {
        background-color: var(--collection-color);
    }
    
    .color-3 {
        background-color: #BFAF9F;
    }
    
    .color-4 {
        background-color: #2B2B2B;
    }


}
