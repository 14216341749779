@import '../../global.css';
.grid-layout-moza {
    .grid-item.grid-item-category{
        grid-area: 2 / 10 / 3 / 13;
        text-align: right;
        h2 {
            @mixin layoutcalc font-size, 12, 0.15;
        }
    }

    .grid-item.grid-item-logo {
        grid-area: 2 / 13 / 3 / 16;
    }

    .grid-item-text {
        grid-area: 8 / 12 / 12 / 21;
        /* color: #8A6446; */
    }
    .grid-item-link{
        background: #7C7547;
    }
    .color-1 {
        background-color: #D7D3C1;
    }

    .color-2 {
        background: linear-gradient(135deg, rgb(239, 236, 225) 0, rgb(255, 255, 255) 100%);
        /* margin-top: 6vh; */
    }
}
