@import '../../global.css';

.grid-layout-stoly-konferencyjne {
    .grid-item-category {
        grid-area: 1 / 13 / 2 / 24;
        /* font-size: 2rem; */
    }

    .grid-item-logo
      {
        opacity: 0;
        /* display: none; */
    }
    .grid-item-link {
        background-color: #4d805e;        
    }

    .grid-item-text {
        grid-area: 8 / 13 / 12 / 21;
    }

    .color-1 {
        background-color: #F0F0F0;
        margin: 20px 0;
    }
    .grid-item-title h3 {
            font-size: calc(161.8vh / 12 * 0.24);
        }
}