@import '../../global.css';

.grid-layout-kuchnie {
    .grid-item-logo {
        grid-area: 2/18/3/22;
        opacity: 0;
    }
    
    .grid-item-link {
        background: #ef4700;
        
    }
    .grid-item-text {
        grid-area: 8 / 12 / 12 / 21;
    }
    
    .grid-item-category {
        grid-area: 1 / 18 / 2 / 24;

    }
    .color-1 {
        background-color: #F9E0AA;
    }
    .color-2 {
        background-color: #B6C982;
    }
    .color-3 {
        background-color: #969593;    
    }
    .color-4 {
        background-color: #EF4601;
    }
    .color-5 {
        background-color: #52263F;
    }
    .color-6 {
        background-color: #54050A;
    }
    .color-7 {
        background-color: #191411;
    }
}
