@import '../../global.css';

.grid-layout-h2o {
    .grid-item-category {
        grid-area: 1/10/2/17;
        text-align: right;
        margin-right: var(--gap);
        
        h2 {
            @mixin layoutcalc font-size, 12, 0.24;
        }
    }

    .grid-item-logo {
        grid-area: 8/17/11/21;
        z-index: 2;
        background-color: #eeeff0;
        background-size: 50%;
    }

    .grid-item-text {  
        grid-area: 9 / 5 / 13 / 17;
        color: #2B3E50;
    }

    .grid-item-title {
        
        grid-area: 1 / 1 / 10 /2;
    }
    
    p.grid-item-link {
        background-color: #069DB5;
    }
}
