@import '../../global.css';

.grid-layout-snabb {
    .grid-item-category {
        grid-area: 1 / 18 / 2 / 21;
        text-align: right;
        h2 {
            @mixin layoutcalc font-size, 12, 0.15;
            line-height: 1;
            font-weight: 400;
        }
    }

    .grid-item-logo {
        grid-area: 1 / 21 / 2 / 24;
    }
    
    .grid-item-text {

        grid-area: 9 / 5 / 12 / 14;    

    }
    .grid-item-link {
        background-color: #1F5F7B;        
    }
    
    .grid-item-title {
        grid-row-end: 9;
    }
    
    .grid-item-nav-prev {
        grid-area: 9 / 1 / 11 / 5;
        }

}
