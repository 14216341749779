@import '../../global.css';

.grid-layout-orkus {
    .grid-item-category {
        grid-area: 1 / 11 / 2 / 16;
    }

    .grid-item-logo {
        grid-area: 2 / 16 / 3 / 19;
    }

    .grid-item-text {
        grid-area: 8 / 12 / 12 / 21;
    }
    p.grid-item-link {
        background-color: #2b1104;
    }
}
