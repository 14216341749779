@import '../../global.css';

%common {
    pointer-events: none;
    padding: 0;
    margin: 0;
}

.grid-item-name {
    @extend %common;
}

.grid-item-nav {
    display: grid;
    background: var(--collection-color);
    color: var(--grid-nav-text-color);
    align-items: center;
    justify-content: stretch;
    cursor: pointer;
    @mixin layoutcalc padding, 12, 0.2;
}

.grid-item-nav:hover {
    background: var(--collection-color);
    color: var(--grid-nav-text-hover-color);
    filter: var(--item-shadow);
}

.grid-item-nav-next .icon-nav-arrow {
    margin: 0 0 0 auto;
}

.grid-item-nav-prev .icon-nav-arrow {
    transform: rotate(180deg);
    margin: 0;
}
.grid-item-nav-prev {
    grid-area: 10 / 1 / 12 / 5;
}

.grid-item-nav-next {
    grid-area: 8 / 21 / 10 / 25;
}



.grid-item-category {
 /*   grid-area: 2/ 18 / 2 / 24; */
    display: grid;
    align-items: center;
    
    h2 {
        @mixin layoutcalc font-size, 12, 0.25;
    }
}

.grid-item-link {
    @media (--tablet-landscape){
        display: grid;
        grid-area: 10 / 21 / 11 / 25;
        /* background-color: var(--collection-color); */
        justify-content: center;
        align-items: center;
        /* text-transform: uppercase; */
        margin: 0;
        a {
            color: #fff;
            @mixin layoutcalc font-size, 12, 0.13;
            text-transform: uppercase;
            font-weight: 400;
        }
    }
}

.grid-item-link:hover {
    /* filter: var(--item-shadow); */
}

.grid-item-logo {
    grid-area: 2 / 21 / 4 / 24;
}



.grid-item-text {
    @media (--tablet-landscape){
        
        @extend %common;
        display: grid;
        grid-area: 10 / 11 / 12 / 18;
        color: inherit;
        color: var(--collection-color);
        text-transform: uppercase;
        background: rgb(255, 255, 255);
        font-weight: normal;
        align-content:center;
        @mixin layoutcalc padding, 12, 0.2;

        p {
            @mixin layoutcalc font-size, 12, 0.12;
            line-height: 1.3;
            font-weight: 400;
            text-transform: none;
            
            &:lang(ru) {
                @mixin layoutcalc font-size, 12, 0.123;
            }
            
            a {
               pointer-events: initial;
               color: var(--collection-color);
               text-decoration: underline;
           }
        }
    }     
}



 
.grid-item-title {
    @extend %common;
    color: var(--collection-color);
    display: grid;
    grid-area: 1 / 1 / 10 / 2;
    text-transform: uppercase;
    justify-content:center;
    align-content: end;
    @mixin layoutcalc padding-bottom, 12, 0.15;

        h3 {
            @mixin layoutcalc font-size, 12, 0.3;
            writing-mode: vertical-lr;
        }
}