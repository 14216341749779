@import '../../global.css';

.grid-layout-blokk {
    .grid-item-category {
        display: grid;
        grid-area: 2 / 8 / 2 / 12;
        justify-content: end;
        color: var(--slideshow-nav-bg);
        align-self: flex-end;
    }

    .grid-item-logo {
        grid-area: 3 / 9 / 4 / 12;
    }
    
    .grid-item-link {
        background: #392B1F;
    }

    .grid-item-text {
        grid-area: 9 / 11 / 13 / 21;
        background-color: #FFF;
    }

    .grid-item-title {
        grid-area: 10 / 1 / 7 / 2;
    }

    .color-1 {
        background-color: #DEDCD3;
        margin: 5vh 10px;
    }
}