@import '../../global.css';

.grid-layout-tirion {
    
    .grid-item-category {
        grid-area: 1 / 2 / 2 /11;
        text-align: right;
    }
    
    .grid-item-logo {
        grid-area: 2 / 21 / 3 / 24;
    }
    

    .grid-item-text {
        grid-area: 10 / 5 / 13 / 15;        
    }
    
    .grid-item-link {
        /* background-color: #8B8B8B;  */
        background-color: #7c9c31; 
        margin: 0;
    }
}