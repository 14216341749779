@import '../../global.css';

.grid-layout-pin {
    .grid-item-category {
        grid-area: 1 / 18 / 2 / 20;
        h2 {
            @mixin layoutcalc font-size, 12, 0.17;
        }
    }
    .grid-item-logo {
        grid-area: 1 / 15 / 2 / 18;
    }
    .grid-item-link {
        background: #A18952;
    }
    .grid-item-text {
        grid-area: 8 / 11 / 12 / 21;
    }
}