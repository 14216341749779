@import '../../global.css';

.grid-layout-renato {
    .grid-item-logo {
        grid-area: 2 / 21 / 3 / 24;
    }

    .grid-item-text {
        grid-area: 10 / 8 / 12 / 21;
    }

    .grid-item-category {
        grid-area: 1 / 7 / 2 / 15; 
    }
    .grid-item-link {
        background: #6EA387;
    }
    .color-1 {
        background-color: #DEDCD3;
    }
}
