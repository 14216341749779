@import '../../global.css';

.grid-layout-costa-bravo {
    .grid-item-logo {
        grid-area: 1 / 17 / 3 / 21;
    }
    .grid-item-category {
        grid-area: 1 / 12 / 2 / 17;
    }
    
    .grid-item-text {
        grid-area: 9 / 7 / 12 / 17;
        color: #8A6446;
    }

    .grid-item-link {
        background: #207DA7;
    }
    
    .color-1 {
        background-color: #E2E7EA;
    }
}